/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import GenericService from "@apiService/GenericService";
import countryData from "../ClubCinepolisNew/helpers/countryData.json";
import {
  CityService,
  LanguageService,
  PwaService,
} from "@apiService/tokenService";



import ErrorPopup from "@components/partials/ErrorPopup";
import timeOut from "@assets/pictures/time-out.png";
import userIcon from "../../assets/pictures/svgs/user.svg"
import Icon from "../../assets/pictures/svgs/Icon.svg"
import mailIcon from "../../assets/pictures/svgs/mail.svg"
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { globalConfigContext } from "@context/GlobalConfigContext";

import * as yup from "yup";
// import SocialButton from '@components/partials/SocialButton';
// import { string, object } from 'yup'; // can be used for optimization
import cinepolisSmall from "../../assets/pictures/cinepolis-small.png";
import cinepolisLogo from "../../assets/pictures/svgs/logo-cinepolis.svg";
import cinepolisLogoWhite from "../../assets/pictures/svgs/logo-cinepolis-white.svg";

import { CountryService } from "@apiService/tokenService";

import AuthService from "@apiService/AuthService";
import {
  userLogin,
  socialLogin,
  setLoginError,
  setSignupError,
  userLogout,
  userSignup,
  verifyUser,
  setLoginSuccess,
} from "../../store/users/userActions";
import useCountries from "../../customHooks/useCountries";
import { setShowLoginSignup } from "@store/config/configActions";
import { stopTimer } from "@store/booking/bookingActions";
import leftArrow from "@assets/pictures/svgs/left-arrow.svg";
import google from "@assets/pictures/svgs/google.svg";
import facebook from "@assets/pictures/svgs/facebook.svg";
import { useHistory } from "react-router-dom";
import { fetchCurrentUserDetails } from "@store/users/userActions";
import { withNamespaces } from "react-i18next";
// import { LanguageService } from "@apiService/tokenService";
import useIsMobile from "../../customHooks/useIsMobile";
import Search from "react-bootstrap-icons/dist/icons/search";
import Notification from "react-bootstrap-icons/dist/icons/bell";
import List from "react-bootstrap-icons/dist/icons/list";
import Cross from "react-bootstrap-icons/dist/icons/x";
import ChangeCityLanguagePopup from "@components/partials/ChangeCityLanguagePopup";
import KsaFlag from "@assets/pictures/flag-saudi-arabia-prev.png";
import UaeFlag from "@assets/pictures/flag-uae.png";
import OmanFlag from "@assets/pictures/flag-oman.png";
import BahrainFlag from "@assets/pictures/flag-bahrain.png";
import { titleCase } from "@helper/formatting";
import { TokenService } from "../../ApiServices/tokenService";
import Turnstile from "react-turnstile";

function Header({ t }) {
  const switchLanguageTo = (lang) => {
    LanguageService.saveLanguage(lang);
    window.location.reload();
  };

  let location = useLocation();

  const { arabic, country_data, site_data } = useContext(globalConfigContext);
  const isMobile = useIsMobile();

  // React Bootstrap Modal

  const handleShow = () => dispatch(setShowLoginSignup(true));

  const [showLoginModal, setShowLoginModal] = useState(true);
  const [showSignupModal, setShowSignupModal] = useState(false);

  const [countryDataState, setCountryDataState] = useState();

  const [selected, setselected] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  const city_data = CityService.getCity() ? CityService.getCity() : {};
  console.log(city_data, "city_data");
  // Login Fields
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginOtp, setLoginOtp] = useState("");
  const [loginErrors, setLoginErrors] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [countryError, setCountryError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [promotionAlert, setPromotionAlert] = useState(false);
  const countries = useCountries(countryCode, setCountryCode);
  const [guestFormErrors, setGuestFormErrors] = useState(null);
  const [guestLoading, setGuestLoading] = useState(false);
  const [countriesRemove, setCountriesRemove] = useState([]);

  const genders = ["Male", "Female"];
  const nationalityNameRef = useRef();

  console.log(countries, "countries>>>>>")

  useEffect(() => {
    const countriesRemoveSA = countries?.filter((countries) =>
      countries.country_short_code !== "SA"

    )
    setCountriesRemove(countriesRemoveSA)
  }, [countries])
  console.log(countriesRemove, "countriesRemoveSA>>>>>")


  const SearchNationality = (e) => {
    console.log(e, "inputttttt");
    const newNationalityArr = countryData;
    let arr = [];
    newNationalityArr.map((x) => {
      console.log(x.name, "x.name>>>>");
      if (x.name.toLowerCase().includes(e.toLowerCase())) {
        arr.push(x);
      }
      setCountryDataState(arr);
    });

    if (arr.length === 0) {
      setCountryError("No such country");
      console.log("hello nidhi");
    } else {
      setCountryError("");
    }

    if (e.length <= 0) {
      setCountryDataState(countryData);
    }
  };
  useEffect(() => {
    setCountryDataState(countryData);
  }, []);
  console.log(countryData, "countryData>>>>");
  const NationalInputClick = () => {
    console.log("hello");
    // nationalityNameRef.current.value = "";
  };

  const [clubCinepolisCheck, setClubCinepolisCheck] = useState(false);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState("");
  const [nationalityCode, setNationalityCode] = useState();
  const [nationalityName, setNationalityName] = useState("");

  useEffect(() => {
    // console.log("inside persisit login")
    // if (localStorage.getItem("userdetails") != null && (localStorage.getItem("usertoken") != null)) {
    //   console.log("inside persisit login 1")
    //   TokenService.saveToken(localStorage.getItem("usertoken"));
    //   dispatch(setLoginSuccess(JSON.parse(localStorage.getItem("userdetails"))));
    // }
  }, []);

  const loginSchema = yup.object().shape({
    loginUsername: yup
      .string()
      .required()
      .matches(/(\S+@\S+\.\S+)|(^\+?\d{8,12}$)/),
    loginPassword: yup.string().required(),
  });

  const handleClose = () => {
    if (showResetPassword && !showSignupModal) {
      setShowResetPassword(false);
    } else {
      setShowResetPassword(false);
      setShowLoginModal(true);
      setShowSignupModal(false);
      setLoginErrors(null);
      setSignupErrors(null);
      dispatch(setShowLoginSignup(false));
    }
    setShowMigratedUserMessage(false);
  };

  // Signup Fields
  const [signupFirstName, setSignupFirstName] = useState("");
  const [signupLastName, setSignupLastName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupMobile, setSignupMobile] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupConfirmPassword, setSignupConfirmPassword] = useState("");
  const [signupOtp, setSignupOtp] = useState("");
  const [signupErrors, setSignupErrors] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [originalCity, setOriginalCity] = useState(null);
  const [cities, setCities] = useState(null);
  const [languages, setLanguages] = useState(null);

  console.log(cities, "cities >>>");

  const signupSchema = yup.object().shape({
    signupFirstName: yup.string().required(),
    signupLastName: yup.string().required(),
    signupEmail: yup.string().email().required(),
    signupMobile: yup
      .string()
      .matches(/^\d{8,9}$/)
      .required(),
    signupPassword: yup.string().required(),
    signupConfirmPassword: yup
      .string()
      .matches(new RegExp(String.raw`${signupPassword}`))
      .required(),
    countryCode: yup.string().required(),
    // dob: yup.date().required(),
    // countryCode: yup.string().required(),
  });

  const resetPasswordInputRef = useRef();
  const isFirstLoad = useRef(true);
  const history = useHistory();

  const [keyState, setKeyState] = useState(null);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(null);
  const [resetPasswordValidationError, setResetPasswordValidationError] =
    useState(null);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);

  const [allCountries, setAllCountries] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [OriginalLanguage, setOriginalLanguage] = useState(null);
  // console.log(OriginalLanguage, "allCountriesallCountriesallCountries")

  const [mobileSidebar, setMobileSidebar] = useState(false);

  const isPwa = PwaService.getIsPwa();
  //For Search
  const inptRef = useRef();
  const [mainArr, setMainArr] = useState(null);
  const nowPlayingMovies = useSelector(
    (state) => state.movies.now_playing_movies
  );

  console.log(nowPlayingMovies, "nowPlayingMovies >>>>>>");

  let is_user_new_up = localStorage.getItem("newUser");

  const comingSoonMovies = useSelector(
    (state) => state.movies.coming_soon_movies
  );
  const allComingSoonMovies = useSelector(
    (state) => state.movies.all_coming_soon
  );

  const setNationality = (item) => {
    setNationalityCode(item.code);
    setNationalityName(item.name);
    nationalityNameRef.current.value = item.name;
  };

  const movieSearch = (e) => {
    const newarr = [
      ...nowPlayingMovies,
      ...allComingSoonMovies,
      ...comingSoonMovies,
    ];
    let arr = [];
    newarr.map((x) => {
      if (x.Title.toLowerCase().includes(e.toLowerCase())) {
        arr.push(x);
      }
      setMainArr(arr);
    });

    if (e.length <= 0) {
      setMainArr(null);
    }
  };

  const onSelectOnCut = (ID) => {
    history.push("/movies/" + ID);
    setMainArr(null);
    inptRef.current.value = "";
  };

  const resetPasswordHandler = () => {
    if (resetPasswordLoader) {
      return;
    }
    setResetPasswordError(null);
    setResetPasswordSuccess(null);
    setResetPasswordValidationError(null);
    yup
      .string()
      .required()
      .email()
      .validate(resetPasswordInputRef.current.value)
      .then((valid) => {
        setResetPasswordLoader(true);
        AuthService.SendForgotPasswordEmail({
          user_email: resetPasswordInputRef.current.value,
        })
          .then((response) => {
            if (response && response.status === 200) {
              setResetPasswordSuccess(
                "Reset Password Email Sent To Your Inbox Successfully!"
              );
            }
          })
          .catch((err) => {
            setResetPasswordError(
              err.response ? err.response.data.message : err.message
            );
          })
          .finally(() => setResetPasswordLoader(false));
      })
      .catch((err) => {
        setResetPasswordValidationError("Enter a valid Email Address");
      });
  };

  // Selectors

  const user = useSelector((state) => state.user);
  const show = useSelector((state) => state.config.showLoginPopup);
  const temp_user = useSelector((state) => state.user.temp_user);
  const migrated_user = useSelector((state) => state.user.migrated_user);
  const [showMigratedUserMessage, setShowMigratedUserMessage] = useState(null);

  const [showCityPopup, setShowCityPopup] = useState(false);
  const [showCountryPopup, setShowCountryPopup] = useState(false);
  const [showMaintainancePopup, setShowMaintainancePopup] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const [selectedCity, setSelectedCity] = useState(); // Default value, you can set it based on your needs

  const handleCityChange = (selectedCity) => {
    console.log(`Selected City: ${selectedCity}`);

    setSelectedCity(selectedCity);
  };
  // console.log(selectedCity, "selectedCity>>>>");

  // useEffect(() => {

  //   document.addEventListener('keydown', function (event) {
  //     if (event.keyCode == 13) {
  //       setKeyState("test")
  //       handleLogin()
  //       handleSignup()
  //     }
  //   });

  // }, [keyState])

  // useEffect(()=> {
  //   if(nowPlayingMovies && nowPlayingMovies.length > 0) {
  //     setShowMaintainancePopup(false)
  //   }else {
  //     setShowMaintainancePopup(true)
  //   }
  // },[nowPlayingMovies])

  // useEffect(()=>{

  //   console.log(window.location.hostname , "hostname >>>>>>>>>>>>>>>>>>>>")

  //   if(!is_user_new_up && window.location.hostname == "oman.cinepolisgulf.com"){
  //     localStorage.setItem('newUser',  "1")
  //     window.location.href = "https://muscat.cinepolisgulf.com/?param1=1";
  //   }
  // }, [])

  useEffect(() => {
    const { cityId, cityName } = city_data;
    setSelectedCity(cityName);
  }, [city_data]);

  useEffect(() => {
    if (country_data?.country_id == 1) {
      setShowMaintainancePopup(true);
    } else {
      setShowMaintainancePopup(false);
    }
  }, [country_data]);

  useEffect(() => {
    let hostname = window.location.hostname;
    console.log(hostname, window.location, "hostname");
    const queryParams = new URLSearchParams(window.location.search);
    const param1Value = queryParams.get("param1");
    console.log(queryParams, param1Value != "1", "queryParams");
    if (
      hostname.includes("ksa-app") &&
      isFirstLoad.current &&
      param1Value != "1"
    ) {
      window.location.assign("https://uae-app.cinepolisgulf.com/?param1=1");
      isFirstLoad.current = false;
    }
  }, []);

  const handleClickOutsideSearch = (event) => {
    inptRef.current.value = "";
    setTimeout(() => {
      setMainArr("");
    }, 100);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);

  useEffect(() => {
    console.log("inside persisit login");
    // dispatch(fetchCurrentUserDetails()).catch((err) => console.log(err));

    if (
      localStorage.getItem("userdetails") != null &&
      localStorage.getItem("usertoken") != null
    ) {
      console.log("inside persisit login 1");
      TokenService.saveToken(localStorage.getItem("usertoken"));
      dispatch(
        setLoginSuccess(JSON.parse(localStorage.getItem("userdetails")))
      );
    }

    GenericService.GetCountries()
      .then((response) => {
        if (response && response.status === 200) {
          setAllCountries(response.data.data.countries);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  useEffect(() => {
    let city_data = CityService.getCity();
    let language_data = LanguageService.getLanguage();

    console.log(city_data, "city_datacity_datacity_data");
    console.log(language_data, "language_datalanguage_datalanguage_data");

    if (language_data) {
      setOriginalLanguage(language_data);
    }
  }, []);

  useEffect(() => {
    const city_data = CityService.getCity();

    if (city_data) {
      const { cityId, cityName } = city_data;
      setCityId(cityId);
      setCityName(cityName);
      setOriginalCity(cityName);
    }
  }, [cities, show]);

  useEffect(() => {
    GenericService.GetCities().then((response) => {
      if (response && response.data) {
        setCities(response.data.data);
        console.log(
          response.data.data,
          "lllllllllllllllllllllllllllllllllllllllllllllllllllllllllll"
        );
      }
    });

    GenericService.GetLanguages().then((response) => {
      if (response && response.data) {
        setLanguages(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (migrated_user) {
      // history.push(`/reset-password/${migrated_user.user_id}/${migrated_user.reset_password_token}/?migrated=yes`)
      setShowMigratedUserMessage(true);
      setLoginUsername("");
      setLoginPassword("");
    }
  }, [migrated_user]);

  const switchCountryTo = (value) => {
    // console.log(value, "TTTTTTTTTTTTTTTTTTTTTTTTTTT")

    let cityIdAndNameArr = value.split("TTT");

    CityService.saveCity({
      cityId: cityIdAndNameArr[0],
      cityName: cityIdAndNameArr[1],
    });

    window.location.reload();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoginErrors(null);
    loginSchema
      .validate(
        {
          loginUsername,
          loginPassword,
          countryCode,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        setLoginErrors(null);

        dispatch(
          userLogin(
            {
              email: loginUsername,
              password: loginPassword,
              countryCode: countryCode,
            },
            handleClose
          )
        );
      })
      .catch((errs) => {
        let errorsObj = Object.assign(
          {},
          ...errs.inner.map((obj) => ({ [obj.path]: obj }))
        );
        setLoginErrors(errorsObj);
      });
  };

  const [token, setToken] = useState(null)


  const handleTurnstileSuccess = (id) => {
    setToken(id)
    console.log(id, "onverify  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ")

  }

  const handleTurnstileFailure = (token) => {
    console.log(token, "onload  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ")

  }

  const [omancheck, setomancheck] = useState(JSON.parse(localStorage.getItem("city")));

  useEffect(() => {
    console.log(omancheck)
    console.log(omancheck?.city)
    console.log("checking omancheck")
  }, [omancheck]);

  const handleSignup = (e) => {
    e.preventDefault();

    console.log(signupEmail, "datachecking");
    console.log(signupFirstName, "datachecking");
    console.log(signupLastName, "datachecking");
    console.log(countryCode, "datachecking");
    console.log(signupMobile, "datachecking");
    console.log(signupPassword, "datachecking");
    console.log(dob, "datachecking");
    console.log(gender, "datachecking");
    console.log(clubCinepolisCheck, "datachecking");

    if (clubCinepolisCheck) {
      console.log(user.current_user && !temp_user && clubCinepolisCheck, "user.current_user && !temp_user && clubCinepolisCheck ")
      console.log("heloooo nidhi>>>>")

      if (dob == "") {
        console.log("heloooo nidhi>>>111>")
        setdobError("Required");
      } else {
        setdobError("");
      }


      if (gender == null) {
        console.log("heloooo nidhi>>>11>")
        setGenderError("Gender Is Required");
      } else {
        setGenderError("");
      }


      if (nationalityName == "") {
        console.log("heloooo nidhi>>>>")
        setCountryError("nationality Is Required")
      } else {
        setCountryError("")
      }

    }
    setSignupErrors(null);
    signupSchema
      .validate(
        {
          signupFirstName,
          signupLastName,
          signupEmail,
          signupPassword,
          signupConfirmPassword,
          signupMobile,
          countryCode,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        console.log("helooooo112")
        if (clubCinepolisCheck) {

          // console.log("heloooo nidhi>>>>")
          //  if(dob !== "" ){
          //   setdobError("")
          //  }

          //  if(gender !== null){
          //   setGenderError("")
          //  }


          //  if(nationalityName !== ""){
          //   setCountryError("")
          //  }
          console.log(dob, gender, nationalityName, "yyyyyyy")
          if (dob == "" || gender == null || nationalityName == "") {
            console.log("heloooo nidhi>>>>2222")
            return
          }


        }

        setSignupErrors(null);
        dispatch(
          userSignup({
            email: signupEmail,
            first_name: signupFirstName,
            last_name: signupLastName,
            mobile: `+${countryCode}${signupMobile}`,
            password: signupPassword,
            dob: dob,
            gender: gender,
            isPromotionAlertActive: promotionAlert == true ? "N" : "N",
            isLoyalty: clubCinepolisCheck == true ? "Y" : "N",
            nationalityName:
              (nationalityName == "" || nationalityName == null) &&
                countryError !== ""
                ? null
                : nationalityName
            ,
            token: token
          })
        );
      })
      .catch((errs) => {
        let errorsObj = Object.assign(
          {},
          ...errs.inner.map((obj) => ({ [obj.path]: obj }))
        );
        setSignupErrors(errorsObj);
      });
  };

  const handleLogout = (type) => {
    dispatch(userLogout());
    localStorage.setItem("usertoken", null);
    localStorage.setItem("userdetails", null);
    history.replace("/");
    // if (window.location.pathname === "/my-profile") {
    //   console.log("JIJJJJJJJJJJJJJJJJJJJ")
    //   history.replace("/");
    // }else if(type === "mobile" ){
    //   history.replace("/");
    // }
  };

  const handleVerify = (type) => {
    dispatch(
      verifyUser(
        {
          email: temp_user ? user.temp_user.email : migrated_user.email,
          otp: type === "login" ? loginOtp : signupOtp,
          migrated_user: migrated_user ? true : false,
        },
        type,
        handleClose,
        redirectToResetPassword
      )
    );
  };

  const redirectToResetPassword = (data) => {
    history.push(`/reset-password/${data.user_id}/${data.reset_token}`);
  };

  let showLogin = (e) => {
    setShowLoginModal(true);
    setShowSignupModal(false);
  };

  let showSignUp = (e) => {
    setShowSignupModal(true);
    setShowLoginModal(false);
  };

  const sideBar = function () {
    setMobileSidebar(true);
    document.querySelector(".mobile-sidebar").classList.add("showbar");
  };
  const hideBar = function () {
    setMobileSidebar(false);
    document.querySelector(".mobile-sidebar").classList.remove("showbar");
  };
  const onClickMainLogo = () => {
    history.push("/");
    dispatch(stopTimer());
  };

  const handleSocialLogin = (type, data) => {
    console.log(data);
    dispatch(
      socialLogin(
        {
          type,
          access_token: data._token.accessToken,
          user_id: data._profile.id ? data._profile.id : null,
        },
        handleClose
      )
    );
  };
  const currentPage = location.pathname.split("/")[1];
  if (["select-country"].includes(currentPage)) {
    return null;
  }

  const handleSocialLoginFailure = (type, data) => {
    console.log(data);
    dispatch(setShowLoginSignup(true));
    // console.log(Object.keys(data));
    // dispatch(setLoginError(data.ORIGINAL_ERROR.error));
    // dispatch(setSignupError(data.ORIGINAL_ERROR.error))
  };

  const selectCountry = (country) => {
    // // {country.website_url ? `${getTargetUrl(country)}${isPwa ? "?ispwa=true" : ""}` : "/"}
    // let targetUrl = `${getTargetUrl(country, isPwa)}${isPwa ? "?ispwa=true" : ""
    //   }`;

    const countryArrForSave = country.split("TTT");

    let hostname = window.location.hostname;
    let countryRedirectionUrl;

    if (hostname.includes("app")) {
      if (countryArrForSave[2].includes("ksa")) {
        countryRedirectionUrl = "https://ksa-app.cinepolisgulf.com/?param1=1";
      } else if (countryArrForSave[2].includes("uae")) {
        countryRedirectionUrl = "https://uae-app.cinepolisgulf.com/?param1=1";
      } else if (countryArrForSave[2].includes("bahrain")) {
        countryRedirectionUrl =
          "https://bahrain-app.cinepolisgulf.com/?param1=1";
      } else {
        countryRedirectionUrl = "https://oman.cinepolisgulf.com/?param1=1";
      }
    } else {
      let is_user_new = localStorage.getItem("newUser");

      if (countryArrForSave[2].includes("om") && !is_user_new) {
        localStorage.setItem("newUser", "1");
        countryRedirectionUrl = "https://oman.cinepolisgulf.com/?param1=1";
      } else {
        countryRedirectionUrl = countryArrForSave[2];
      }
    }

    // if (hostname.includes("localhost")) {
    //   if (countryArrForSave[2].includes("ksa")) {
    //     countryRedirectionUrl = "";
    //   } else if (countryArrForSave[2].includes("uae")) {
    //     countryRedirectionUrl = "http://localhost:3000/";
    //   } else if (countryArrForSave[2].includes("bahrain")) {
    //     countryRedirectionUrl = "http://localhost:3000/";
    //   } else {
    //     countryRedirectionUrl = "http://localhost:3000/";
    //   }
    // } else {
    //   countryRedirectionUrl = countryArrForSave[2];
    // }

    console.log(
      countryRedirectionUrl,
      hostname,
      countryArrForSave,
      "countryRedirectionUrl-hostname-countryarray"
    );

    if (countryArrForSave && countryArrForSave.length > 0) {
      CountryService.saveCountry({
        country_name: countryArrForSave[1],
        country_id: countryArrForSave[0],
        redirect_url: countryRedirectionUrl,
      });
      window.location.href = countryRedirectionUrl;
    }
  };

  console.log(signupMobile, "signupMobile>>");

  return (
    <>
      {/* START DESKTOP HEADER */}
      <ErrorPopup
        className="maintainence-popup"
        show={showMaintainancePopup}
        showEmoji1={false}
        action={() => setShowMaintainancePopup(true)}
        title={t("SITE UNDER MAINTAINENCE !")}
        subtitle={t("We will be back soon.")}
        subtitleTwo={t("For bookings, please visit our Concessions Counter.")}
      />
      <div className="sticky-top custom-desktop-navbar d-none d-md-block">
        <div className="container-fluid">
          <div className="row header-row-first d-flex justify-content-between align-items-center">
            <div style={{ margin: "0", padding: "0" }} className="col-6">
              <div className="grid-dropdown">
                <Dropdown
                  onSelect={(eventKey) => switchLanguageTo(eventKey)}
                  className="custom-dropdown-1"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {OriginalLanguage ? OriginalLanguage.toUpperCase() : "EN"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-language-alignment">
                    {OriginalLanguage == "ar" ? (
                      <Dropdown.Item eventKey="en" className="text-center">
                        {OriginalLanguage != null ? "EN" : ""}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item eventKey="ar" className="text-center">
                        {OriginalLanguage != null ||
                          OriginalLanguage == undefined
                          ? "AR"
                          : ""}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  onSelect={(eventKey) => selectCountry(eventKey)}
                  className="custom-dropdown-2 dropdown-country-alignment"
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-country-alignment"
                  >
                    {country_data &&
                      country_data.country_short_code == "SA" ? (
                      <span>
                        <img
                          className="dropdown-country-change-flag"
                          src={KsaFlag}
                          alt="flag"
                        />{" "}
                        KSA{" "}
                      </span>
                    ) :
                      country_data.country_short_code == "AE" ? (
                        <span>
                          <img
                            className="dropdown-country-change-flag"
                            src={UaeFlag}
                            alt="flag"
                          />{" "}
                          UAE{" "}
                        </span>
                      ) : country_data.country_short_code == "OM" ? (
                        <span>
                          <img
                            className="dropdown-country-change-flag"
                            src={OmanFlag}
                            alt="flag"
                          />{" "}
                          OMAN{" "}
                        </span>
                      ) : (
                        <span>
                          <img
                            className="dropdown-country-change-flag"
                            src={BahrainFlag}
                            alt="flag"
                          />{" "}
                          BAHRAIN{" "}
                        </span>
                      )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {allCountries &&
                      allCountries.length > 0 &&
                      allCountries
                        .filter(
                          (item) => item?.country_id != country_data?.country_id
                        )
                        .map((item) => {
                          return (
                            <Dropdown.Item
                              className={`${item?.country_id == country_data?.country_id
                                ? "custom-dropdown-item dropdown-country-alignment"
                                : ""
                                }`}
                              eventKey={
                                item.country_id +
                                "TTT" +
                                item.country_name +
                                "TTT" +
                                item.prod_website_url
                              }
                            >
                              {item.country_short_code == "SA" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={KsaFlag}
                                    alt="flag"
                                  />{" "}
                                  KSA{" "}
                                </span>
                              ) : item.country_short_code == "AE" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={UaeFlag}
                                    alt="flag"
                                  />{" "}
                                  UAE{" "}
                                </span>
                              ) : item.country_short_code == "OM" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={OmanFlag}
                                    alt="flag"
                                  />{" "}
                                  OMAN{" "}
                                </span>
                              ) : (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={BahrainFlag}
                                    alt="flag"
                                  />{" "}
                                  BAHRAIN{" "}
                                </span>
                              )}
                            </Dropdown.Item>
                            // <div>Hello</div>
                          );
                        })}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <>
                  {window?.location?.host == "localhost:3000" ||
                    window?.location?.host == "https://oman.cinepolisgulf.com" ||
                    window?.location?.host == "https://oman.cinepolisgulf.com" ||
                    window?.location?.host?.includes("oman.cinepolisgulf.com") ? (
                    <Dropdown
                      className={`custom-dropdown-2 dropdown-country-alignment  ${arabic ? "custom-dropdown-1ar" : ""
                        }`}
                      onSelect={handleCityChange}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className={`text-uppercase ${arabic
                          ? " d-flex justify-content- align-items-center "
                          : ""
                          }`}
                      >
                        {selectedCity}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-uppercase fs-7"
                          href="https://muscat.cinepolisgulf.com/"
                        >
                          Muscat
                        </Dropdown.Item>
                        
                        <Dropdown.Item
                          className="text-uppercase fs-7"
                          href="https://oman.cinepolisgulf.com/"
                        >
                          Sohar / Salalah
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </> */}
              </div>
            </div>
            <div className="col-6">
              <div className="footer-social-icons mr-5">
                <a href="https://www.facebook.com/CinepolisGULF/">
                  <i class="ri-facebook-fill"></i>
                </a>
                <a
                  href={
                    country_data && country_data.country_short_code == "SA"
                      ? "https://www.instagram.com/cinepolis.ksa/?hl=en"
                      : country_data.country_short_code == "AE"
                        ? "https://www.instagram.com/cinepolis.uae/?hl=en"
                        : country_data.country_short_code == "OM"
                          ? " https://www.instagram.com/cinepolis.oman/?hl=en"
                          : "https://www.instagram.com/cinepolis.bahrain/?hl=en"
                  }
                >
                  <i class="ri-instagram-line"></i>
                </a>
                <a
                  href="https://twitter.com/cinepolisgulf?lang=en"
                  style={{ height: "18px", width: "18px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    fill="currentColor"
                  >
                    <path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid container-spacing py-3 navbar-max-width">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className={`${!arabic ? "pr-2" : "pl-2"}`}>
                <figure
                  className={`cursor-pointer mxh-70 img-fluid ${arabic ? "text-right" : "text-left"
                    }`}
                  onClick={() => onClickMainLogo()}
                >
                  <img
                    src={cinepolisLogo}
                    alt="cinepolis-logo"
                    className="mxh-70 img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-10">
              <ul className="list-inline header-items">
                {/* <li className="list-inline-item">
                                <div className="dropdown-bg">
                                    <Dropdown className="w-100 h-100 pr-4">
                                        <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
                                            SELECT CITY
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdownItems">
                                            <Dropdown.Item href="#/action-1" className="border-bottom">SAN FRANCISCO</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" className="border-bottom">AMSTERDAM</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">SHIMLA</Dropdown.Item>
                                            </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li> */}
                {/* <li className="list-inline-item">
                                <div className="dropdown-bg">
                                    <Dropdown className="w-100 h-100 pr-4">
                                        <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
                                            EN
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdownItems">
                                            <Dropdown.Item href="#/action-1" className="border-bottom">ENGLISH</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" className="border-bottom">SANSKRIT</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                                            </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li> */}
                <li className="list-inline-item search-input-field d-none d-xl-block">
                  <input
                    type="text"
                    className="form-control movie-search-input"
                    ref={inptRef}
                    onKeyUp={(e) => movieSearch(e.target.value)}
                  />
                  <figure>
                    <Search className="movie-search" />
                  </figure>
                  {mainArr && mainArr.length > 0 && (
                    <div className="typo-li-type" o>
                      {mainArr.map((el, ind) => (
                        <p key={ind} onClick={() => onSelectOnCut(el.ID)}>
                          {el.Title}
                        </p>
                      ))}
                    </div>
                  )}
                </li>
                <li className="list-inline-item">
                  {!location.pathname.startsWith(
                    "/mexico-film-festival-movies"
                  ) ? (
                    <Link to="/movies-list">
                      {t("header-footer-menu.movies")}
                    </Link>
                  ) : null}
                </li>
                {/* {country_data &&
                (country_data.country_short_code === "AE" ||
                country_data.country_short_code === "OM" ||
                country_data.country_short_code === "BH") &&
                site_data && (
                <li className="list-inline-item">
                  <Link to="/fifa">
                    {t("header-footer-menu.fifa")}
                  </Link>
                </li>
                )} */}
                {true && omancheck?.cityId == 4 && (
                  <li className="list-inline-item">
                    <Link to="/club-cinepolis">Club Cinepolis</Link>
                  </li>
                )}
                {/* {true && (
                  <li className="list-inline-item">
                    <Link to="/games">Games</Link>
                  </li>
                )} */}
                {true && (
                  <li className="list-inline-item">
                    <Link to="/promotion">
                      {t("header-footer-menu.promotions")}
                    </Link>
                  </li>
                )}
                {/* {country_data &&
                country_data.country_short_code === "SA" &&
                site_data &&
                site_data.mexican_ff === "Y" && (
                  <Link
                    
                    to="/ibero-american-film-festival"
                  >
                    {t("header-footer-menu.film-festival")}
                  </Link>
                )} */}

                {/* <Linken
                    className="header-links"
                    to="/ramadan-film-festival"
                  >
                    {t("header-footer-menu.ramadan-festival")}
                  </Link> */}

                {/* 
{
              country_data && country_data.country_short_code == "SA" &&(
<li className="list-inline-item">
                  <Link to="/avail-offer-ksa-massarah">
                    {t("header-footer-menu.massarah")}
                  </Link>
                </li>
                
              )
             } */}

                <li className="list-inline-item">
                  <Link to="/book-event">{t("header-footer-menu.events")}</Link>
                </li>

                <li className="list-inline-item">
                  <Link to="/experiences">
                    {t("header-footer-menu.experiences")}
                  </Link>
                </li>

                {country_data &&
                  country_data.country_short_code === "SA" &&
                  site_data && (
                    <li className="list-inline-item">
                      <Link to="/luxury">{t("luxury-page.luxury")}</Link>
                    </li>
                  )}

                {user?.current_user?.email == "sakirshaikh0777@gmail.com" && (
                  <li className="list-inline-item">
                    <Link to="/book-concession">
                      {t("book-concession-page.book-concession")}
                    </Link>
                  </li>
                )}

                {/* <li className="list-inline-item">
                                <Link  to="#">
                                    {t('header-footer-menu.fnb')}
                                </Link>
                            </li> */}
                <li className="list-inline-item">
                  <Link to="/cinema">{t("header-footer-menu.locations")}</Link>
                </li>
                {/* <li className="list-inline-item">
                  <Link to="/book-event">
                    {arabic ? "احجز حدثًا " : "BOOK AN EVENT"}
                  </Link>
                </li> */}
                {user.current_user && (
                  // <li className="list-inline-item">
                  //   <Link to="/my-profile">
                  //     {t("header-footer-menu.profile")}
                  //   </Link>
                  // </li>
                  <li className="list-inline-item ruchi-code">
                    <Dropdown className="custom-dropdown-5">
                      <Dropdown.Toggle id="dropdown-basic">
                        <div>
                          <figure>
                            <svg
                              width="20px"
                              height="20px"
                              version="1.1"
                              viewBox="0 0 1200 1200"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m600.02 653.34c134.25 0 256.29 54.902 344.7 143.32 88.453 88.453 143.35 210.46 143.35 344.7v19.043c0 15.227-12.348 27.578-27.578 27.578h-921c-15.227 0-27.578-12.348-27.578-27.578v-19.043c0-134.25 54.902-256.25 143.35-344.7 88.453-88.453 210.46-143.32 344.7-143.32zm0-641.35c70.883 0 135.07 28.727 181.55 75.203 46.477 46.477 75.203 110.63 75.203 181.52 0 70.883-28.727 135.07-75.203 181.55-46.477 46.477-110.63 75.203-181.55 75.203-70.883 0-135.07-28.727-181.55-75.203-46.477-46.441-75.203-110.63-75.203-181.55 0-70.883 28.727-135.07 75.203-181.52 46.477-46.477 110.66-75.203 181.55-75.203z"
                                fill="#586BF1"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </figure>
                          <span>Hi, {user.current_user?.first_name}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/my-profile">
                            {t("header-footer-menu.profile")}
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                          {t("header-footer-menu.logout")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}

                {/* <li className="list-inline-item">
                  {arabic ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => switchLanguageTo("en")}
                    >
                      ENG
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={() => switchLanguageTo("ar")}
                    >
                      العربية
                    </span>
                  )}
                </li> */}
                {!user.current_user && (
                  <li className="list-inline-item">
                    <button
                      className="btn btn-purple btn-auth"
                      to=""
                      onClick={handleShow}
                    >
                      {t("signin-up")}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* END DESKTOP HEADER */}

      {/* MOBILE HEADER */}
      <div className="sticky-top-mobile custom-mobile-navbar d-block d-md-none">
        <div className="container-fluid pb-3">
          <div className="row align-items-center">
            <div className="col-lg-6 px-0 mb-2">
              <div className="grid-dropdown">
                <Dropdown
                  onSelect={(eventKey) => switchLanguageTo(eventKey)}
                  className="custom-dropdown-1"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {OriginalLanguage ? OriginalLanguage.toUpperCase() : "EN"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-language-alignment">
                    {OriginalLanguage == "ar" ? (
                      <Dropdown.Item eventKey="en" className="text-center">
                        {OriginalLanguage != null ? "EN" : ""}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item eventKey="ar" className="text-center">
                        {OriginalLanguage != null ||
                          OriginalLanguage == undefined
                          ? "AR"
                          : ""}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  onSelect={(eventKey) => selectCountry(eventKey)}
                  className="custom-dropdown-2 dropdown-country-alignment"
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-country-alignment"
                  >
                    {country_data && country_data.country_short_code == "SA" ? (
                      <span>
                        <img
                          className="dropdown-country-change-flag"
                          src={KsaFlag}
                          alt="flag"
                        />{" "}
                        KSA{" "}
                      </span>
                    ) : country_data.country_short_code == "AE" ? (
                      <span>
                        <img
                          className="dropdown-country-change-flag"
                          src={UaeFlag}
                          alt="flag"
                        />{" "}
                        UAE{" "}
                      </span>
                    ) : country_data.country_short_code == "OM" ? (
                      <span>
                        <img
                          className="dropdown-country-change-flag"
                          src={OmanFlag}
                          alt="flag"
                        />{" "}
                        OMAN{" "}
                      </span>
                    ) : (
                      <span>
                        <img
                          className="dropdown-country-change-flag"
                          src={BahrainFlag}
                          alt="flag"
                        />{" "}
                        BAHRAIN{" "}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {allCountries &&
                      allCountries.length > 0 &&
                      allCountries
                        .filter(
                          (item) => item?.country_id != country_data?.country_id
                        )
                        .map((item) => {
                          return (
                            <Dropdown.Item
                              className={`${item?.country_id == country_data?.country_id
                                ? "custom-dropdown-item dropdown-country-alignment"
                                : ""
                                }`}
                              eventKey={
                                item.country_id +
                                "TTT" +
                                item.country_name +
                                "TTT" +
                                item.prod_website_url
                              }
                            >
                              {item.country_short_code == "SA" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={KsaFlag}
                                    alt="flag"
                                  />{" "}
                                  KSA{" "}
                                </span>
                              ) : item.country_short_code == "AE" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={UaeFlag}
                                    alt="flag"
                                  />{" "}
                                  UAE{" "}
                                </span>
                              ) : item.country_short_code == "OM" ? (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={OmanFlag}
                                    alt="flag"
                                  />{" "}
                                  OMAN{" "}
                                </span>
                              ) : (
                                <span>
                                  <img
                                    className="dropdown-country-change-flag"
                                    src={BahrainFlag}
                                    alt="flag"
                                  />{" "}
                                  BAHRAIN{" "}
                                </span>
                              )}
                            </Dropdown.Item>
                            // <div>Hello</div>
                          );
                        })}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <>
                  {window?.location?.host == "localhost:8000" ||
                    window?.location?.host == "https://oman.cinepolisgulf.com" ||
                    window?.location?.host?.includes("oman.cinepolisgulf.com") ? (
                    <Dropdown
                      className={`custom-dropdown-2 dropdown-country-alignment  ${arabic ? "custom-dropdown-1ar" : ""
                        }`}
                      onSelect={handleCityChange}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className={`text-uppercase ${arabic
                          ? " d-flex justify-content- align-items-center "
                          : ""
                          }`}
                      >
                        {selectedCity}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-uppercase fs-7"
                          href="https://muscat.cinepolisgulf.com/"
                        >
                          Muscat
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="text-uppercase fs-7"
                          href="https://oman.cinepolisgulf.com/"
                        >
                          Sohar / Salalah
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </> */}
              </div>
            </div>
            <div className="col-6">
              <div className={`${!arabic ? "pr-2" : "pl-2"}`}>
                <figure
                  className={`cursor-pointer ${arabic ? "text-right" : "text-left"
                    }`}
                  onClick={() => onClickMainLogo()}
                >
                  <img
                    src={cinepolisLogo}
                    alt="cinepolis-logo"
                    className="mxh-70 img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-6">
              <div className="header-icons">
                {/* <div>
                  <Notification  />
                </div> */}
                <div>
                  <Link to="/search-movie">
                    <Search />
                  </Link>
                </div>
                <div>
                  {!mobileSidebar ? (
                    <div>
                      <List onClick={sideBar} />
                    </div>
                  ) : (
                    <Cross onClick={hideBar} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-sidebar d-block d-md-none">
        <div className="d-none">
          <ul className="list-unstyled mb-0 frs-20">
            <li className="">
              <Link to="/movies-list">{t("header-footer-menu.movies")}</Link>
            </li>
            <li className="">
              <Link to="">{t("header-footer-menu.cinemas")}</Link>
            </li>
            <li className="">
              <Link to="/experiences">{t("experiences")}</Link>
            </li>
            <li className="">
              <Link to="">{t("header-footer-menu.fnb")}</Link>
            </li>
            {true && (
              <li className="">
                <Link to="/promotion">
                  {t("header-footer-menu.promotions")}
                </Link>
              </li>
            )}
            {/* {!user.current_user && (
              <li className="list-inline-item mt-4">
                <button
                  className="btn btn-purple fs-14 px-5"
                  onClick={handleShow}
                >
                  LOGIN/SIGNUP
                </button>
              </li>
            )} */}
          </ul>
        </div>
        <div>
          <ul className="list-unstyled mb-0 frs-20">
            <li>
              <Link to="/movies-list" onClick={hideBar}>
                {t("header-footer-menu.movies")}
              </Link>
            </li>

            {
              country_data && country_data.country_short_code == "OM"
                ? <li>
                  <Link to="/club-cinepolis" onClick={hideBar}>
                    Club Cinepolis
                  </Link>
                </li>
                : <></>
            }

            <li>
              <Link to="/about-us" onClick={hideBar}>
                {t("about-us")}
              </Link>
            </li>
            <li>
              <Link to="/book-event" onClick={hideBar}>
                {t("header-footer-menu.events")}
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={hideBar}>
                {t("header-footer-menu.faq")}
              </Link>
            </li>
            <li>
              <Link to="/feedback" onClick={hideBar}>
                {t("footer.Feedback")}
              </Link>
            </li>
            <li>
              <Link to="/tnc" onClick={hideBar}>
                {t("footer.Terms of Service")}
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" onClick={hideBar}>
                {t("footer.Privacy Policy")}
              </Link>
            </li>

            {/* {
              country_data && country_data.country_short_code == "SA" &&(
            <li>
              <Link to="/avail-offer-ksa-massarah" onClick={hideBar}>
                {t("footer.massarah")}
              </Link>
            </li>
                
              )
             } */}
            {country_data && country_data.country_short_code == "SA" ? (
              <li>
                <Link to="/luxury" onClick={hideBar}>
                  {t("luxury-page.luxury")}
                </Link>
              </li>
            ) : null}
            {/* <li className="list-inline-item"><a href="#" className="">{t('footer.Disclaimer')}</a></li> */}
            {/* <li>
              <Link
                className=""
                to="/select-country"
                // onClick={() => setShowCountryPopup(true)}
              >
                <p className={`${arabic ? "" : ""}`}>
                  {t("profile_page.change-country")}
                </p>
              </Link>
            </li> */}
            {/* <li>
            <Link onClick={() => setShowCityPopup(true)}>{t("city-n-lang1")} </Link> */}
            {/* <div onClick={() => setShowCityPopup(true)}>
              
              </div> */}
            {/* </li> */}
            {user && user.current_user && (
              <li>
                <Link onClick={() => handleLogout("mobile")}>Logout</Link>
                {/* <div onClick={() => setShowCityPopup(true)}>
              
              </div> */}
              </li>
            )}
            {/* <li className="list-inline-item mt-4">
                <button
                  className="btn btn-purple fs-14 px-5"
                  onClick={handleShow}
                >
                  LOGIN/SIGNUP
                </button>
              </li> */}
          </ul>
        </div>
        <div className={`sidebar-footer-bottom ${arabic ? "" : ""}`}>
          {t("footer.copyright")}
        </div>
      </div>
      {/* END MOBILE HEADER */}

      <ChangeCityLanguagePopup
        show={showCityPopup}
        onClose={() => setShowCityPopup(false)}
      />

      <Modal
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-1 login-signup-modal"
      >
        <div className="custom-modal-1">
          <div className="container-fluid container-spacing px-0">
            <div className="row align-items-center py-3">
              <div className="col-lg-2 px-3 px-lg-0">
                <div className="cursor-pointer" onClick={handleClose}>
                  <img src={leftArrow} alt="arrow" className="icon-18 mr-2" />
                  {t("common.Back")}
                </div>
              </div>
              <div className="col-lg-8">
                <ul className="list-inline mb-0 feature-links d-flex justify-content-around">
                  <li className="list-inline-item">
                    <Link
                      to=""
                      id="loginBtn"
                      className={showLoginModal ? "active" : ""}
                      onClick={showLogin}
                    >
                      {t("header-footer-menu.login")}
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to=""
                      id="signupBtn"
                      className={showSignupModal ? "active" : ""}
                      onClick={showSignUp}
                    >
                      {t("header-footer-menu.signup")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!showResetPassword && showLoginModal && (
            <form onSubmit={handleLogin}>
              <Container fluid id="login" className="">
                {showMigratedUserMessage && (
                  <Alert variant="dark">
                    {t("reset_pwd_page.website-upgrade-message")}
                  </Alert>
                )}
                {user.login_error && (
                  <Alert variant="dark">{user.login_error.message}</Alert>
                )}
                {isMobile && (
                  <figure
                    className={`cursor-pointer mxh-70 d-flex justify-content-center my-3 img-fluid ${arabic ? "text-right" : "text-left"
                      }`}
                    onClick={() => onClickMainLogo()}
                  >
                    <img
                      src={cinepolisLogo}
                      alt="cinepolis-logo"
                      className="mxh-70 img-fluid"
                    />
                  </figure>
                )}
                {/* <Row className="justify-content-center pt-2">
                  <Col xs={12} sm={8} md={8}>
                    <p className="text-center frm-24 frs-20 kumbh fw-bold pb-1 lh-1">
                      {t("login-heading")}
                    </p>
                  </Col>
                </Row> */}
                {/* <Row className="mb-4 justify-content-center">
                  <Col xs={12} sm={8} md={8}>
                    <p className="text-center frl-16 frm-14 frs-12 kumbh fw-reg">
                      {t("login-desc")}
                    </p>
                  </Col>
                </Row> */}
                {!user.current_user &&
                  !temp_user &&
                  !migrated_user && [
                    <Row className="justify-content-center mb-3 mt-4 nidhi">
                      <Col sm={10} md={12} lg={12} className="">
                        <input
                          className="form-control custom-form-1"
                          onChange={(e) => setLoginUsername(e.target.value)}
                          type="text"
                          placeholder={t(
                            "login_form.placeholders.email-mobile"
                          )}
                        />
                        {loginErrors && loginErrors.loginUsername && (
                          <span className="form-error-text d-block text-align-center">
                            {t("login_form.error_messages.email_mobile_number")}
                          </span>
                        )}
                      </Col>
                    </Row>,
                    <Row className="d-flex justify-content-center mb-2">
                      <Col sm={10} md={12} lg={12} className="">
                        <input
                          className="form-control custom-form-1"
                          onChange={(e) => setLoginPassword(e.target.value)}
                          type="password"
                          placeholder={t("login_form.placeholders.pwd")}
                        />
                        {loginErrors && loginErrors.loginPassword && (
                          <span className="form-error-text d-block text-align-center">
                            {t("login_form.error_messages.password")}
                          </span>
                        )}
                      </Col>
                    </Row>,
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <div
                          onClick={() => setShowResetPassword(true)}
                          className="mx-auto  mt-2"
                        >
                          <p className="text-grey kumbh fw-reg frxl-16 frs-14 cursor-pointer mb-1">
                            <u>{t("login_form.forgot-password")}</u>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ]}

                {(temp_user || migrated_user) &&
                  ((temp_user && temp_user.is_verified === "N") ||
                    migrated_user) ? (
                  <Row className="d-flex justify-content-center">
                    <Col sm={10} md={8} lg={6} className="">
                      <input
                        className="form-control custom-form-1"
                        onChange={(e) => setLoginOtp(e.target.value)}
                        type="text"
                        placeholder={t("login_form.placeholders.enter-otp")}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row className="mb-4">
                  <Col className="d-flex justify-content-center align-items-center">
                    {!user.current_user && !temp_user && !migrated_user && (
                      <button
                        className="btn btn-purple px-5"
                        to=""
                        onClick={handleLogin}
                      >
                        {user.login_loading ? (
                          <Spinner
                            animation="border"
                            className="spinner"
                            role="status"
                          >
                            <span className="sr-only">{t("loading")}</span>
                          </Spinner>
                        ) : (
                          t("header-footer-menu.login")
                        )}
                      </button>
                    )}
                    {(temp_user || migrated_user) &&
                      ((temp_user && temp_user.is_verified === "N") ||
                        migrated_user) ? (
                      <Link
                        className="blue-btn frxl-16 frs-14 mxh-47 px-3 px-md-4 px-lg-5"
                        to=""
                        onClick={() => handleVerify("login")}
                      >
                        {t("VERIFY")}
                      </Link>
                    ) : null}
                  </Col>
                </Row>
                {/* <Row className="mb-4">
                            <Col className="d-flex justify-content-center align-items-center">
                            <p className="frxl-24 frl-22 frs-20 kumbh fw-bold">
                                {t('common.OR')}
                            </p>
                            </Col>
                        </Row> */}
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm={8}
                    md={6}
                    lg={5}
                    className="text-center text-sm-left mb-3 mb-md-0 nidhi1"
                  >
                    {/* <div
                                    className="brds-10 d-flex align-items-center bg-light-black-2 mxh-47 w-100 cursor-pointer"
                                >
                                    <p className="frl-14 frs-12 kumbh fw-reg px-3">
                                    <img src={google} className="mxh-29 pr-1 vab" alt="google"/> {t('form_general.google')}
                                    </p>
                                </div> */}
                    {/* <SocialButton 
                                    type="google"
                                    provider="google"
                                    appId="934634427947-84bo7dhp14ksut2r92q2nb2ckgbp3fbf.apps.googleusercontent.com"
                                    onLoginSuccess={(resp) => handleSocialLogin('google', resp)}
                                    onLoginFailure={(resp) => handleSocialLoginFailure('google', resp)}
                                >
                                    {t('form_general.google')}
                                </SocialButton> */}
                  </Col>
                  {/* <Col sm={8} md={6} lg={5} className="text-center mb-3 mb-md-0">
                                <SocialButton 
                                    type="facebook"
                                    provider="facebook"
                                    appId="891286754608896"
                                    scope="email,"
                                    onLoginSuccess={(resp) => handleSocialLogin('facebook', resp)}
                                    onLoginFailure={(resp) => handleSocialLoginFailure('facebook', resp)}
                                >
                                    {t('form_general.facebook')}
                                </SocialButton>
                            </Col> */}
                </Row>
              </Container>
            </form>
          )}

          {showResetPassword && showLoginModal && (
            <Container fluid className="">
              {(resetPasswordSuccess || resetPasswordError) && (
                <Alert variant={resetPasswordSuccess ? "success" : "danger"}>
                  {resetPasswordSuccess
                    ? resetPasswordSuccess
                    : resetPasswordError}
                </Alert>
              )}
              <Row className="justify-content-center">
                <Col xs={12} sm={8} md={8}>
                  <p className="text-center frm-16 frs-14 mt-3 kumbh fw-bold pb-3 lh-1">
                    {t("form_general.reset-pwd")}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col xs={12} sm={8} md={8}>
                  <p className="text-center frm-14 frs-12 kumbh fw-reg">
                    {t("form_general.reset-pwd-subtitle")}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col sm={10} md={8} lg={6} className="">
                  <input
                    className="form-control custom-form-1"
                    type="email"
                    placeholder={t("login_form.placeholders.reset-pwd-email")}
                    ref={resetPasswordInputRef}
                  />
                  {resetPasswordValidationError && (
                    <span className="form-error-text d-block text-align-center">
                      {resetPasswordValidationError}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="d-flex justify-content-center align-items-center">
                  <Link
                    className="btn btn-purple px-5 mt-3"
                    to=""
                    onClick={resetPasswordHandler}
                  >
                    {resetPasswordLoader ? (
                      <Spinner
                        animation="border"
                        className="spinner"
                        role="status"
                      >
                        <span className="sr-only">{t("loading")}</span>
                      </Spinner>
                    ) : (
                      t("form_general.reset-pwd-btn")
                    )}
                  </Link>
                </Col>
              </Row>
            </Container>
          )}

          {showSignupModal && (
            <form onSubmit={handleSignup}>
              <Container fluid id="signup" className="">
                {user.signup_error && (
                  <Alert variant="dark">{user.signup_error.message}</Alert>
                )}
                {/* <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={8}>
                    <p className="text-center frm-24 frs-20 kumbh fw-bold mb-20">
                      {t("signup-heading")}
                    </p>
                  </Col>
                </Row> */}
                {/* <Row className="mb-4 justify-content-center">
                  <Col xs={12} sm={8} md={8}>
                    <p className="text-center frl-16 frm-14 frs-12 kumbh fw-reg">
                      {t("signup-desc")}
                    </p>
                  </Col>
                </Row> */}
                {isMobile && (
                  <figure
                    className={`cursor-pointer mxh-70 d-flex justify-content-center my-3 img-fluid ${arabic ? "text-right" : "text-left"
                      }`}
                    onClick={() => onClickMainLogo()}
                  >
                    <img
                      src={cinepolisLogo}
                      alt="cinepolis-logo"
                      className="mxh-70 img-fluid"
                    />
                  </figure>
                )}

                {!user.current_user &&
                  !temp_user && [
                    <Row className="flex-wrap justify-content-center mb-md-0 mt-4 nidhi">
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2  text-center text-sm-left"
                      >
                        {/* <img src={userIcon} /> */}
                        <input
                          className="form-control custom-form-1"
                          required
                          onChange={(e) => setSignupFirstName(e.target.value)}
                          type="text"
                          placeholder={t("signup_form.placeholders.first_name")}
                        />
                        {signupErrors && signupErrors.signupFirstName && (
                          <span className="form-error-text d-block text-align-center">
                            {t("signup_form.errors.first_name")}
                          </span>
                        )}
                      </Col>
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2  text-center text-sm-left"
                      >
                        <input
                          className="form-control custom-form-1 "
                          required
                          onChange={(e) => setSignupLastName(e.target.value)}
                          type="text"
                          placeholder={t("signup_form.placeholders.last_name")}
                        />
                        {signupErrors && signupErrors.signupLastName && (
                          <span className="form-error-text d-block text-left">
                            {t("signup_form.errors.last_name")}
                          </span>
                        )}
                      </Col>
                    </Row>,
                    <Row className="flex-wrap mb-md-0 justify-content-center">
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2 text-center text-sm-left"
                      >
                        <input
                          className="form-control custom-form-1 "
                          onChange={(e) => setSignupEmail(e.target.value)}
                          required
                          type="email"
                          placeholder={t("signup_form.placeholders.email")}
                        />
                        {signupErrors && signupErrors.signupEmail && (
                          <span className="form-error-text d-block text-left">
                            {t("signup_form.errors.email")}
                          </span>
                        )}
                      </Col>
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2 mb-md-0 text-center text-sm-left"
                      >
                        <div className="mobile-dropdown-code">
                          <Dropdown className="w-100 h-100 cus-dropdown-2">
                            <Dropdown.Toggle
                              className="dropdownClassic"
                              id="dropdown-basic"
                              disabled={true}
                            >
                              {countryCode ? "+" + countryCode : "Loading"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdownItems">
                              {countriesRemove && countriesRemove?.length > 0
                                ? countriesRemove?.map((country, index) => (
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      setCountryCode(country.country_code);
                                    }}
                                    key={index}
                                    className=""
                                  >
                                    {" "}
                                    +{country.country_code} (
                                    {country.country_short_code})
                                  </Dropdown.Item>
                                ))
                                : null}
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* {signupErrors && signupErrors.signupEmail && (
                            <span className="form-error-text d-block text-left">
                              {t("signup_form.errors.email")}
                            </span>
                          )} */}
                          <input
                            className="form-control custom-form-1 mb-2"
                            onChange={(e) => {
                              setSignupMobile(e.target.value);
                            }}
                            required
                            type="tel"
                            placeholder={t("signup_form.placeholders.mobile")}
                            maxlength={`${countryCode == "968" ? "8" : "9"}`}
                          />
                        </div>
                        {signupErrors && signupErrors.signupMobile && (
                          <span className="form-error-text d-block text-left">
                            {country_data.country_short_code === "OM"
                              ? t("signup_form.errors.mobile_oman")
                              : t("signup_form.errors.mobile")}
                          </span>
                        )}
                      </Col>
                    </Row>,
                    <Row className="d-flex  justify-content-center">
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2 text-center text-sm-left"
                      >
                        <input
                          className="form-control custom-form-1 mb-2"
                          required
                          onChange={(e) => setSignupPassword(e.target.value)}
                          type="password"
                          placeholder={t("signup_form.placeholders.pwd")}
                        />
                        {signupErrors && signupErrors.signupPassword && (
                          <span className="form-error-text d-block text-left">
                            {t("signup_form.errors.pwd")}
                          </span>
                        )}
                      </Col>
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="  text-center text-sm-left nidhi2"
                      >
                        <input
                          className="form-control custom-form-1 mb-2"
                          required
                          type="password"
                          onChange={(e) =>
                            setSignupConfirmPassword(e.target.value)
                          }
                          placeholder={t("signup_form.placeholders.cpwd")}
                        />
                        {signupErrors && signupErrors.signupConfirmPassword && (
                          <span className="form-error-text d-block text-left">
                            {t("signup_form.errors.cpwd")}
                          </span>
                        )}
                      </Col>
                    </Row>,
                  ]}

                {!user.current_user && !temp_user && clubCinepolisCheck ? (
                  <>
                    <Row className="d-flex  justify-content-center">
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-2  text-center text-sm-left"
                      >
                        <Dropdown className="w-100 h-100 cus-dropdown-2">
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            id="dropdown-basic"
                          >
                            {gender ? (
                              gender
                            ) : (
                              <span className="club-placeholder">
                                Select Gender
                              </span>
                            )}
                          </Dropdown.Toggle>

                          {genders && genders.length > 0 && (
                            <Dropdown.Menu className="dropdownItems frxl-18 frs-16">
                              {genders.map((item, index) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    setGender(item);
                                  }}
                                  key={index}
                                  className=" text-wrap"
                                >
                                  {item}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        {genderError && genderError !== "" && (
                          <span className="form-error-text d-block text-align-center">
                            {genderError}
                          </span>
                        )}
                      </Col>
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className="mb-3  text-center text-sm-left"
                      >
                        <input
                          className="form-control custom-form-1"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          required
                          type="date"
                        />
                        {dobError && dobError !== "" && (
                          <span className="form-error-text d-block text-left">
                            {dobError}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        sm={8}
                        md={12}
                        lg={12}
                        className=" mb-md-0 text-center text-sm-left "
                      >
                        <Dropdown className="w-100 h-100 cus-dropdown-2 drop-nationality-items ">
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            id="dropdown-basic"
                          >
                            {/* {countryDataState ? (
                            countryDataState
                          ) : (
                            <span className="club-placeholder">Select Nationality</span>
                          )} */}

                            <span className="">
                              <input
                                ref={nationalityNameRef}
                                type="text"
                                onChange={(e) =>
                                  SearchNationality(e.target.value)
                                }
                                placeholder="Select Nationality"
                                onClick={() => NationalInputClick()}
                                className="border-0"
                              // value={nationalityName}
                              />
                            </span>
                          </Dropdown.Toggle>

                          {countryDataState && countryDataState.length > 0 && (
                            <Dropdown.Menu className="dropdownItems frxl-18 frs-16">
                              {countryDataState.map((item, index) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    setNationality(item);
                                  }}
                                  key={index}
                                  className=" text-wrap"
                                >
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        {countryError && countryError !== "" && (
                          <span className="form-error-text d-block text-align-center">
                            {countryError}
                          </span>
                        )}
                        {/* {signupErrors && signupErrors.nationalityCode && (
                        <span className="form-error-text d-block text-align-center">
                          Nationality Is Required
                        </span>
                      )} */}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {temp_user && temp_user.is_verified === "N" ? (
                  <Row className="d-flex justify-content-center">
                    <Col sm={10} md={10} lg={8} className="">
                      <input
                        className="form-control custom-form-1"
                        onChange={(e) => setSignupOtp(e.target.value)}
                        type="text"
                        placeholder={t("login_form.placeholders.enter-otp")}
                      />
                    </Col>
                  </Row>
                ) : null}

                {!temp_user && (
                  <section className="row">
                    <article className="col-12 text-left">
                      <label className="mx-auto frs-14">
                        <input
                          checked={promotionAlert}
                          onChange={(e) => setPromotionAlert(e.target.checked)}
                          type="checkbox"
                          className="mr-2"
                          style={{ marginBottom: "10px" }}
                        />
                        {t("signup_form.promotional-alert")}
                      </label>
                    </article>
                  </section>
                )}


                {!temp_user && country_data && country_data.country_short_code == "OM" && (
                  <section className="row">
                    <article className="col-12 text-left">
                      <label className="mx-auto frs-14">
                        <input
                          checked={clubCinepolisCheck}
                          onChange={(e) =>
                            setClubCinepolisCheck(e.target.checked)
                          }
                          type="checkbox"
                          className="mr-2"
                        />
                        I'd like to join Club Cinepolis
                      </label>
                    </article>
                  </section>
                )}

                <Turnstile
                  sitekey="0x4AAAAAAAb4fZldzYI5e5_7"
                  // sitekey="0x4AAAAAAAb4fZldzYI5e5_7"
                  onVerify={(e) => handleTurnstileSuccess(e)}
                  onLoad={(e) => handleTurnstileFailure(e)}
                // style={{text-align:"center"}}
                // onVerify={(handleClick) => {
                //   fetch("/", {
                //     method: "POST",
                //     body: JSON.stringify({ handleClick }),
                //   }).then((response) => {
                //     console.log(response, "response????");
                //     if (!response.ok) turnstile.reset();
                //   });
                // }}
                />

                {/* {!user.current_user &&
                !temp_user && [
                  <Row className="mb-4">
                    <Row className="d-flex mb-4 justify-content-center">
                      <Col className="">
                        <label>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="mr-2 ml-4"
                            checked={promotionAlert}
                            onChange={(e) =>
                              setPromotionAlert(e.target.checked)
                            }
                          />
                          {t("signup_form.promotional-alert")}
                        </label>
                      </Col>
                    </Row>
                  </Row>,
                ]} */}
                <Row className="">
                  <Col className="d-flex justify-content-center align-items-center">
                    {!user.current_user && !temp_user && (
                      <button
                        className="btn btn-purple px-5 mb-2"
                        to=""
                        type="submit"
                        onClick={handleSignup}

                      >
                        {user.signup_loading ? (
                          <Spinner
                            animation="border"
                            className="spinner"
                            role="status"
                          >
                            <span className="sr-only">{t("loading")}</span>
                          </Spinner>
                        ) : (
                          t("header-footer-menu.signup")
                        )}{" "}
                      </button>
                    )}
                    {temp_user && temp_user.is_verified === "N" ? (
                      <Link
                        className="blue-btn frl-14 mxh-47 px-3 px-md-4 px-lg-5"
                        to=""
                        onClick={() => handleVerify("signup")}
                      >
                        {t("VERIFY")}
                      </Link>
                    ) : null}
                  </Col>
                </Row>

                {/* <Row>
                            <Col className="d-flex justify-content-center">
                            <div className="d-flex mb-4 justify-content-center align-items-center">
                            <p className="frxl-24 frl-22 frs-20 kumbh fw-bold">
                                {t('common.OR')}
                            </p>
                        </div>
                            </Col>
                        </Row> */}

                <Row className="d-flex flex-wrap  justify-content-center">
                  <Col
                    sm={8}
                    md={12}
                    lg={12}
                    className="text-center  mb-md-0"
                  >
                    {/* <SocialButton 
                                    type="google"
                                    provider="google"
                                    appId="934634427947-84bo7dhp14ksut2r92q2nb2ckgbp3fbf.apps.googleusercontent.com"
                                    onLoginSuccess={(resp) => handleSocialLogin('google', resp)}
                                    onLoginFailure={(resp) => handleSocialLoginFailure('google', resp)}
                                >
                                    {t('form_general.google')}
                                </SocialButton> */}
                  </Col>
                  {/* <Col sm={8} md={6} lg={5} className="text-center mb-3 mb-md-0">
                                <SocialButton 
                                    type="facebook"
                                    provider="facebook"
                                    appId="891286754608896"
                                    scope="email,"
                                    onLoginSuccess={(resp) => handleSocialLogin('facebook', resp)}
                                    onLoginFailure={(resp) => handleSocialLoginFailure('facebook', resp)}
                                >
                                    {t('form_general.facebook')}
                                </SocialButton>
                            </Col> */}
                </Row>
              </Container>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
}

export default withNamespaces()(Header);
