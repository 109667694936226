import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
import CustomerSpinner from "@components/partials/Spinner";
import HomeCustomSpinner from "@components/partials/HomeSpinner";
import giftTicketContainer from './components/GiftTicketContainer'
import FinalTicketContainerBrother from './components/FinalTicketContainerBrother'
import { useLocation } from "react-router-dom";
import AvailOfferUaeMicrosite from "./components/AvailOfferUaeMicrosite";
import NewBanner from "./components/NewBanner";
import LoyaltyRegisterStatic from "./components/partials/LoyaltyRegisterStatic";

//import components
// import mainHomeContainer from '@components/mainHomeContainer.js';
// import CinemaContainer from '@components/CinemaContainer';


const mainHomeContainer = lazy(() => import("@components/mainHomeContainer"));
const OfferPage = lazy(() => import("@components/OfferPage"));

const Login = lazy(() => import("@components/NewDesigns/Login"));
const Payment = lazy(() => import("@components/flow-timeline/Payment"));

const Success = lazy(() => import("@components/Popups/Success"));
const Error = lazy(() => import("@components/Popups/Error"));
const TimeOut = lazy(() => import("@components/Popups/TimeOut"));
const Important = lazy(() => import("@components/Popups/Important"));


// import MovieContainer from '@components/MovieContainer.js';
const MovieContainer = lazy(() => import("@components/MovieContainer"));

const ClubCinepolisSignUp = lazy(() =>
  import("@components/ClubCinepolisNew/ClubCinepolisSignUp")
);

// import SeatLayoutContainer from '@components/SeatLayoutContainer';
const SeatLayoutContainer = lazy(() =>
  import("@components/SeatLayoutContainer")
);

// import FnbContainer from '@components/FnbContainer';
const FnbContainer = lazy(() => import("@components/FnbContainer"));

//import CheckoutContainer from '@components/CheckoutContainer';
const CheckoutContainer = lazy(() => import("@components/CheckoutContainer"));

//import SummaryPage from '@components/SummaryPage';
const SummaryPage = lazy(() => import("@components/SummaryPage"));

// import FinalTicketContainer from '@components/FinalTicketContainer';
const FinalTicketContainer = lazy(() =>
  import("@components/FinalTicketContainer")
);

const FnbDetailsContainer = lazy(() =>
  import("@components/FnbDetailsContainer")
);

const CinemaContainer = lazy(() => import("@components/CinemaContainer"));

const BookConcession = lazy(() => import("@components/BookConcession"));
const ClubCinepolisRedemption = lazy(() =>
  import("@components/ClubCinepolisNew/ClubCinepolisRedemption")
);

// import PromotionContainer from '@components/PromotionContainer';
const PromotionContainer = lazy(() => import("@components/PromotionContainer"));

// import MovieListsContainer from '@components/MovieListsContainer';
const MovieListsContainer = lazy(() =>
  import("@components/MovieListsContainer")
);


// import TransactionFailedContainer from '@components/TransactionFailedContainer';
const TransactionFailedContainer = lazy(() =>
  import("@components/TransactionFailedContainer")
);

// import Page404 from '@components/404';
const Page404 = lazy(() => import("@components/404"));

// import CountrySelectorContainer from '@components/CountrySelectorContainer';
const CountrySelectorContainer = lazy(() =>
  import("@components/CountrySelectorContainer")
);

const MovieViewMore = lazy(() =>
  import("@components/MovieViewMore")
);

//Others
// import HomeContainer from '@components/HomeContainer';
const HomeContainer = lazy(() => import("@components/HomeContainer"));


// import TodoContainer from '@components/TodoContainer';
const TodoContainer = lazy(() => import("@components/TodoContainer"));

// import TermsAndConditionsContainer from '@components/TermsAndConditionsContainer';
const TermsAndConditionsContainer = lazy(() =>
  import("@components/TermsAndConditionsContainer")
);

// import FAQsContainer from '@components/FAQsContainer';
const FAQsContainer = lazy(() => import("@components/FAQsContainer"));

// import ExperiencesContainer from '@components/ExperiencesContainer';
const ExperiencesContainer = lazy(() =>
  import("@components/ExperiencesContainer")
);

const LuxuryContainer = lazy(() =>
  import("@components/LuxuryContainer")
);

// import UserProfileContainer from '@components/UserProfileContainer';
const UserProfileContainer = lazy(() =>
  import("@components/UserProfileContainer")
);

// import ProfileDetails from '@components/ProfileDetails';
const ProfileDetails = lazy(() => import("@components/ProfileDetails"));


const applePay = lazy(() => import("@components/applePay"));

// import cont from '@components/continue';
const cont = lazy(() => import("@components/continue"));

// import loginSignup from '@components/loginSignup';
const loginSignup = lazy(() => import("@components/loginSignup"));

// import guest from '@components/guest';
const guest = lazy(() => import("@components/guest"));

// import ResetPasswordContainer from '@components/ResetPasswordContainer';
const ResetPasswordContainer = lazy(() =>
  import("@components/ResetPasswordContainer")
);

// import PrivacyPolicyContainer from '@components/PrivacyPolicyContainer';
const PrivacyPolicyContainer = lazy(() =>
  import("@components/PrivacyPolicyContainer")
);

// import FeedbackContainer from '@components/FeedbackContainer';
const FeedbackContainer = lazy(() => import("@components/FeedbackContainer"));

// import SearchMovieContainer from '@components/SearchMovieContainer';
const SearchMovieContainer = lazy(() =>
  import("@components/SearchMovieContainer")
);

// import AboutUSContainer from '@components/AboutUSContainer'; 
const AboutUSContainer = lazy(() => import("@components/AboutUSContainer"));

// import ChangeCountryCityLanguage from '@components/ChangeCountryCityLanguage';
const ChangeCountryCityLanguage = lazy(() => import("@components/ChangeCountryCityLanguage"));

// import ComingSoonSelector from '@components/ComingSoonSelector';
const ComingSoonSelector = lazy(() => import("@components/ComingSoonSelector"));

// import FnbView from '@components/FnbView';
const FnbView = lazy(() => import("@components/FnbView"));

const loginJs = lazy(() => import("@components/loginSignup"));

const FoodBeverageContainer = lazy(() => import("@components/FoodBeverageContainer"));


const FifaContainer = lazy(() => import("@components/FifaContainer"));


// import mexicoFilmFestival from '@components/MexicoFilmFestival';
const mexicoFilmFestival = lazy(() => import("@components/MexicoFilmFestival"));

const ramadanFilmFestival = lazy(() => import("./components/RamadanFilmFestival"));

const credimaxPaymentConstainer = lazy(() => import("./components/CredimaxPaymentContainer"));
const credimaxPaymentConstainerAmwaj = lazy(() => import("./components/CredimaxPaymentContainerAmwaj"));

// import AvailOfferOman from '@components/AvailOfferOman';
const AvailOfferOman = lazy(() => import("@components/AvailOfferOman"));
const AvailOfferOmanFinal = lazy(() =>
  import("@components/AvailOfferOmanFinal")
);

//centerpoint Micosrosite
const AvailOfferCenterPointKsa = lazy(() =>
  import("@components/AvailOfferKsaCenterpoint")
);
const AvailOfferCenterPointBahrain = lazy(() =>
  import("@components/AvailOfferBahrainCenterpoint")
);

const AvailOfferAlOsraBahrain = lazy(() =>
  import("@components/AvailOfferAlOsraBahrain")
);

const AvailOfferDammamMicrosite = lazy(() =>
  import("@components/AvailOfferDammamMicrosite")
);

// Duotix Component
const DuotixLandingPage = lazy(() =>
  import("@components/DuotixPassComponent/DuotixLandingPage")
);

const DuotixSelectionPage = lazy(() =>
  import("@components/DuotixPassComponent/DuotixSelectionPage")
);

const DuotixCheckoutPage = lazy(() =>
  import("@components/DuotixPassComponent/DuotixCheckoutPage")
);

// import AvailOfferOman from '@components/AvailOfferOman';
const BookAnEvent = lazy(() => import("@components/BookAnEvent"));

const ExperiencesNewContainer = lazy(() =>
  import("@components/ExperienceNew")
);
// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const Router = () => {

  let location = useLocation();
  console.log('location>>>', location)

  return (<>
    <Suspense fallback={location.pathname == "/" ? <HomeCustomSpinner active={true} /> : <CustomerSpinner active={true} />}>
      <Switch>

        <Route exact path="/offers/:session_id/:cinema_id" component={OfferPage} />
        <Route exact path="/applePay" component={applePay} />
        <Route exact path="/login/:session_id/:cinema_id" component={Login} />
        <Route exact path="/payment" component={Payment} />


        <Route exact path="/success-popup" component={Success} />
        <Route exact path="/error-popup" component={Error} F />
        <Route exact path="/timeout-popup" component={TimeOut} />
        <Route exact path="/important-popup" component={Important} />
        <Route exact path="/" component={mainHomeContainer} />
        <Route exact path="/movies-list" component={MovieListsContainer} />
        <Route exact path="/movies/:movie_id" component={MovieContainer} />
        <Route
          exact
          path="/seatlayout/:session_id/:cinema_id"
          component={SeatLayoutContainer}
        />
        <Route
          exact
          path="/fnb/:session_id/:cinema_id"
          component={FnbContainer}
        />
        <Route exact path="/fnb-view" component={FnbView} />
        <Route
          exact
          path="/checkout/:reservation_id"
          component={CheckoutContainer}
        />

        <Route
          exact
          path="/ticket/:reservation_id"
          component={FinalTicketContainer}
        />
        <Route exact path="/cinema" component={CinemaContainer} />
        <Route exact path="/book-concession" component={BookConcession} />
        <Route exact path="/my-profile" component={ProfileDetails} />
        <Route exact path="/promotion" component={PromotionContainer} />
        {/* <Route exact path="/login" component={loginJs} /> */}
        <Route
          exact
          path="/promotion/:promotion_id"
          component={PromotionContainer}
        />
        {/* Others */}
        <Route exact path="/games" component={FifaContainer} />
        <Route exact path="/sfifa" component={FifaContainer} />
        <Route exact path="/home" component={HomeContainer} />
        <Route exact path="/user/profile" component={UserProfileContainer} />
        <Route exact path="/experiences" component={ExperiencesContainer} />
        <Route exact path="/luxury" component={LuxuryContainer} />
        <Route exact path="/tnc" component={TermsAndConditionsContainer} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyContainer} />
        <Route exact path="/feedback" component={FeedbackContainer} />
        <Route exact path="/search-movie" component={SearchMovieContainer} />
        <Route exact path="/faq" component={FAQsContainer} />
        <Route exact path="/todos" component={TodoContainer} />
        <Route exact path="/fnb-page" component={FoodBeverageContainer} />
        <Route exact path="/order-summary" component={SummaryPage} />
        <Route exact path="/experience-new" component={ExperiencesNewContainer} />
        <Route
          exact
          path="/transaction-failed"
          component={TransactionFailedContainer}
        />
        <Route exact path="/login-signup" component={loginSignup} />
        <Route exact path="/guest" component={guest} />
        <Route exact path="/continue" component={cont} />
        <Route
          exact
          path="/reset-password/:userid/:token"
          component={ResetPasswordContainer}
        />
        <Route
          exact
          path="/select-country"
          component={CountrySelectorContainer}
        />
        <Route
          exact
          path="/FnbDetailsContainer/:session_id/:cinema_id"
          component={FnbDetailsContainer}
        />
        <Route
          exact
          path="/movie-view-more"
          component={MovieViewMore}
        />
        <Route exact path="/about-us" component={AboutUSContainer} />
        <Route exact path="/country-city-language" component={ChangeCountryCityLanguage} />
        <Route exact path="/coming-soon" component={ComingSoonSelector} />


        <Route exact path="/club-cinepolis" component={LoyaltyRegisterStatic} />

        <Route
          exact
          path="/ibero-american-film-festival"
          component={mexicoFilmFestival}
        />
        <Route
          exact
          path="/ramadan-film-festival"
          component={ramadanFilmFestival}
        />
        <Route exact path="/avail-offer-ksa" component={AvailOfferOman} />
        <Route exact path="/avail-offer-oman" component={AvailOfferOmanFinal} />
        <Route
          exact
          path="/avail-offer-ksa-massarah"
          component={AvailOfferCenterPointKsa}
        />
        <Route
          exact
          path="/avail-offer-bahrain-centerpoint"
          component={AvailOfferCenterPointBahrain}
        />
        <Route
          exact
          path="/avail-offer-al-osra-bahrain"
          component={AvailOfferAlOsraBahrain}
        />
        <Route
          exact
          path="/avail-offer-dammam-microsite"
          component={AvailOfferDammamMicrosite}
        />
        <Route
          exact
          path="/avail-offer-uae-microsite"
          component={AvailOfferUaeMicrosite}
        />
        <Route exact path="/book-event" component={BookAnEvent} />

        <Route
          exact
          path="/ticket/:reservation_id/:relation"
          component={FinalTicketContainerBrother}
        />
        <Route
          exact
          path="/payment/credimax"
          component={credimaxPaymentConstainer}
        />
        <Route
          exact
          path="/paymentcredimaxamwaj"
          component={credimaxPaymentConstainerAmwaj}
        />

        {/* Loyalty Pages  */}
        <Route exact path="/club-cinepolis-signup" component={ClubCinepolisSignUp} />
        <Route exact path="/club-cinepolis-redemption" component={ClubCinepolisRedemption} />


        {/* Duotix Components */}
        <Route exact path="/duotix-landing-page" component={DuotixLandingPage} />
        <Route exact path="/duotix-selection-page" component={DuotixSelectionPage} />
        <Route exact path="/duotix-checkout-page/:reservation_id" component={DuotixCheckoutPage} />

        <Route component={Page404} />
        {/* </AnimatedSwitch> */}
      </Switch>
    </Suspense>
  </>)
}

export default Router;
