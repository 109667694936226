import React from 'react'
import Slider from 'react-slick';
import arrow11 from "../assets/pictures/arrows.png"
import useShowOscarFilmFest from '../customHooks/useShowOscarFilmFest';
import { useHistory } from 'react-router-dom';

const NewBanner = ({ banners }) => {

    const showOscarFilmFest = useShowOscarFilmFest();
    // let banners = [
    //     {
    //         "movie_id": null,
    //         "banner_title": null,
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1381,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1711453803241blob",
    //         "redirect_url": "/games",
    //         "m_banner_type": "popup_promotion",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-26T11:50:06.000Z",
    //         "updated_at": "2024-03-26T11:50:06.000Z",
    //         "order": 0,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     },
    //     {
    //         "movie_id": null,
    //         "banner_title": "IPL MATCHES",
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1380,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1711441139071blob",
    //         "redirect_url": "/games",
    //         "m_banner_type": "generic",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-26T08:19:08.000Z",
    //         "updated_at": "2024-03-26T08:19:08.000Z",
    //         "order": 0,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     },
    //     {
    //         "movie_id": null,
    //         "banner_title": null,
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1368,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1709817768722blob",
    //         "redirect_url": null,
    //         "m_banner_type": "generic",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-07T13:22:51.000Z",
    //         "updated_at": "2024-03-07T13:22:51.000Z",
    //         "order": 1,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     },
    //     {
    //         "movie_id": null,
    //         "banner_title": "Ramadan",
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1370,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1710147526986blob",
    //         "redirect_url": null,
    //         "m_banner_type": "generic",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-11T08:58:54.000Z",
    //         "updated_at": "2024-03-11T08:58:54.000Z",
    //         "order": 2,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     },
    //     {
    //         "movie_id": null,
    //         "banner_title": "Skip-The-Queue-Fnb-Popup",
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1374,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1710431738030blob",
    //         "redirect_url": null,
    //         "m_banner_type": "generic",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-14T15:55:39.000Z",
    //         "updated_at": "2024-03-14T15:55:39.000Z",
    //         "order": 3,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     },
    //     {
    //         "movie_id": 7940,
    //         "banner_title": null,
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1364,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1710940023440blob",
    //         "redirect_url": null,
    //         "m_banner_type": "movie",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-03-05T17:25:54.000Z",
    //         "updated_at": "2024-03-05T17:25:54.000Z",
    //         "order": 4,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": "The Goat Life (Aadujeevitham) (Malayalam)",
    //         "movie_title_ar": "",
    //         "movie_description": "Based on Benyamin’s The Goat life, the true life story of Najib, a man who leaves behind his wife, mother and his unborn child and goes from the lush green lands of Kerala, India to a barren desert country in the middle-East in search for a better life. He is forced into hard labour in a Goat farm where he develops a unique relationship with his herd. Najib’s escape from the almost impossible situation after 3 years from this sandy grave is the tale which has been told in THE GOAT LIFE, universal tale of loneliness & alienation.",
    //         "movie_description_ar": "",
    //         "run_time": 173,
    //         "movie_trailer": "https://www.youtube.com/watch?v=qvsiJKdDxPs&t=18s",
    //         "ID": "HO00006406",
    //         "Title": "The Goat Life (Aadujeevitham) (Malayalam)",
    //         "Synopsis": "Based on Benyamin’s The Goat life, the true life story of Najib, a man who leaves behind his wife, mother and his unborn child and goes from the lush green lands of Kerala, India to a barren desert country in the middle-East in search for a better life. He is forced into hard labour in a Goat farm where he develops a unique relationship with his herd. Najib’s escape from the almost impossible situation after 3 years from this sandy grave is the tale which has been told in THE GOAT LIFE, universal tale of loneliness & alienation.",
    //         "movie_release_date": "2024-03-28T00:00:00.000Z",
    //         "rating": "15+"
    //     },
    //     {
    //         "movie_id": 7797,
    //         "banner_title": null,
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1336,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1708522760967blob",
    //         "redirect_url": null,
    //         "m_banner_type": "movie",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2023-11-09T10:12:10.000Z",
    //         "updated_at": "2023-11-09T10:12:10.000Z",
    //         "order": 5,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": "Dune: Part Two",
    //         "movie_title_ar": "",
    //         "movie_description": "Paul Atreides unites with Chani and the Fremen while on a warpath of revenge against the conspirators who destroyed his family. Facing a choice between the love of his life and the fate of the known universe, he endeavors to prevent a terrible future only he can foresee.",
    //         "movie_description_ar": "",
    //         "run_time": 166,
    //         "movie_trailer": "https://www.youtube.com/watch?v=GN2p-O86E6s",
    //         "ID": "HO00006268",
    //         "Title": "Dune: Part Two",
    //         "Synopsis": "Paul Atreides unites with Chani and the Fremen while on a warpath of revenge against the conspirators who destroyed his family. Facing a choice between the love of his life and the fate of the known universe, he endeavors to prevent a terrible future only he can foresee.",
    //         "movie_release_date": "2024-02-29T00:00:00.000Z",
    //         "rating": "PG 15"
    //     },
    //     {
    //         "movie_id": null,
    //         "banner_title": "Event",
    //         "promotion_id": null,
    //         "country_id": 2,
    //         "m_banner_id": 1359,
    //         "m_banner_image_url_1": "https://cinepoligulf-1.s3.ap-south-1.amazonaws.com/uploads/images/banners/1708947715043blob",
    //         "redirect_url": null,
    //         "m_banner_type": "generic",
    //         "show_advanced_booking_tag": "N",
    //         "banner_is_active": "Y",
    //         "created_by": null,
    //         "updated_by": null,
    //         "created_at": "2024-02-26T11:42:07.000Z",
    //         "updated_at": "2024-02-26T11:42:07.000Z",
    //         "order": 8,
    //         "custom_btn_message": null,
    //         "custom_btn_message_ar": null,
    //         "is_image_compressed": "N",
    //         "movie_title": null,
    //         "movie_title_ar": null,
    //         "movie_description": null,
    //         "movie_description_ar": null,
    //         "run_time": null,
    //         "movie_trailer": null,
    //         "ID": null,
    //         "Title": null,
    //         "Synopsis": null,
    //         "movie_release_date": null,
    //         "rating": null
    //     }
    // ]

    const history = useHistory();

    const handleBannerRedirecton = (ID, banner) => {

        console.log(banner, "banner clciked")

        if (banner.redirect_url == null || banner.redirect_url == "") {
            if (banner.ID) {
                history.push(`/movies/${banner.ID}`);
            }
            else if (banner.banner_title == "Event") {
                history.push(`/book-event`)
            }
            else {
                history.push(`/movies-list`);
            }
        }
        else {
            history.push(banner.redirect_url);
        }
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: "transparent", background: "none", right: `calc(50% - ${880 / 2}px - 10px)`, zIndex: 10, hover: "none" }}
                onClick={onClick}
            >
                {/* <span
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "3px", // Adjust the position of the triangle as needed
                        transform: "translateY(-50%) rotate(90deg)",
                        width: "0",
                        height: "0",
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid white", // Adjust the color of the triangle
                    }}
                >M</span> */}

                <img
                    src={arrow11}// Replace 'path_to_your_arrow_image.png' with the actual path to your arrow image
                    alt="Next"
                    style={{
                        position: "absolute",
                        top: "50%", // Adjust the vertical position of the arrow
                        // right: "10px", // Adjust the position of the arrow as needed
                        transform: "translateY(-50%)",
                        width: "60px", // Adjust the width of the arrow image
                        height: "60px",
                        border: "2px solid white",
                        borderRadius: "50%"
                    }}
                />
            </div>

        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: "transparent", background: "none", left: `calc(50% - ${880 / 2}px - 30px)`, zIndex: 10, hover: "none" }}
                onClick={onClick}
            >
                {/* <span
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "3px", // Adjust the position of the triangle as needed
                        transform: "translateY(-50%) rotate(90deg)",
                        width: "0",
                        height: "0",
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid white", // Adjust the color of the triangle
                    }}
                >M</span> */}

                <img
                    src={arrow11}// Replace 'path_to_your_arrow_image.png' with the actual path to your arrow image
                    alt="Next"
                    style={{
                        position: "absolute",
                        top: "50%", // Adjust the vertical position of the arrow
                        // right: "10px", // Adjust the position of the arrow as needed
                        transform: "translateY(-50%) rotate(180deg)",
                        width: "60px", // Adjust the width of the arrow image
                        height: "60px",
                        border: "2px solid white",
                        borderRadius: "50%"
                    }}
                />
            </div>

        );
    }



    // function SamplePrevArrow(props) {
    //     const { className, style, onClick, currentSlideWidth } = props;
    //     return (
    //         <div
    //             className={className}
    //             style={{ ...style, display: "block", background: "green", left: `calc(50% - ${880 / 2}px - 20px)`, zIndex: 10 }}
    //             onClick={onClick}
    //         />
    //     );
    // }

    const heroSlider1 = {
        // className: "slider variable-width",
        // className: "center",
        // centerPadding: "60px",
        slidesToShow: 1,
        centerMode: true,
        dots: true,
        infinite: true,

        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: true,
        variableWidth: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,


        responsive: [
            {
                breakpoint: 983,
                settings: {
                    // arrows: false,
                    arrows: false,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,

                },
            },

        ],

    };




    const heroSliderMobile = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (


        <div className="">
            <div className="" >
                <div className="slider-container px-0">
                    <Slider {...heroSlider1} className="m-0 p-0" >
                        {banners &&
                            banners.length > 0 &&
                            banners
                                .filter((banner) => {
                                    if (!showOscarFilmFest) {
                                        if (
                                            banner.banner_title &&
                                            banner.banner_title
                                                .toLowerCase()
                                                .includes("oscar film festival")
                                        ) {
                                            return false;
                                        }
                                    }
                                    if (banner.m_banner_type === "popup_promotion") {
                                        return false;
                                    }

                                    return true;
                                }).map((banner, index) => (
                                    <div onClick={() => handleBannerRedirecton(banner.ID, banner)} key={index} style={{ margin: "0", padding: "0" }}>

                                        <div className="banner-hero " style={{ margin: "0", padding: "0" }}>
                                            <div
                                                className={`banner-hero-img ${banner.show_advanced_booking_tag &&
                                                    banner.show_advanced_booking_tag === "Y"
                                                    ? "advance-lg"
                                                    : ""
                                                    } ${banner.m_banner_type === "promotion"
                                                        ? "img-fit"
                                                        : ""
                                                    }`}
                                            >
                                                <figure style={{ margin: "0", padding: "0" }} className="banner-hero-img">
                                                    <img
                                                        style={{ width: "880px" }} className={`img-fluid`}
                                                        src={banner.m_banner_image_url_1}
                                                    />
                                                </figure>
                                            </div>


                                        </div>

                                        {/* <div className="banner-hero " style={{ margin: "0", padding: "0" }}>
                                            <div style={{ margin: "0", padding: "0" }} className="banner-hero-img">
                                                <figure className="hero-img">
                                                    <img style={{ width: "880px" }} className={`img-fluid`} src={banner.m_banner_image_url_1} />
                                                </figure>
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                    </Slider>
                </div>
            </div>
        </div>

    )
}

export default NewBanner